import React from 'react';

function Footer() {
    return (
        <div className="bg-dark">
            <footer className='row pt-3 justify-content-evenly'>
                <div className='col-xs-12'>
                    <p className="text-white text-center">© 2024 Corten</p>
                </div>

                <div className="col-xs-12">
                    <p className="text-white text-center">Av. de los Maestros 1434, Col. Mezquitan Country, Guadalajara, Jalisco</p>
                </div>

            </footer>
        </div>
    );
};

export default Footer;
